(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/**
 * Created by Jaap on 15/04/2016.
 */
require('angular');
require('angular-animate');
require('angular-filter');
require('angular-chart.js') ;
//include('bower_components/angular-chart.js/dist/angular-chart.js');

var myproapp = angular.module('myproapp',['ngAnimate','angular.filter','chart.js']);

myproapp.controller('dnsStatsController', function($scope, $http)
{
    $scope.labels = ["select domain"];

    $scope.series = ['Hits', 'QPS', 'QPS*1k'];
    $scope.values = [[0]];
    $scope.colours = ['#1d3c6b','#ece01a','#006600','#68F000'];

    $scope.onClick = function (points, evt) {
        console.log(points, evt);
    };

    $scope.showGraph = function()
    {
        $scope.dnsdataurl = '/fetchgraphstats/'+$scope.dnsDomainName ;
        $scope.graphactive = true;

        //$scope.qpsValue = [0.934,0.066,0.066];
        //$scope.qpsLabel = ["QPS Used","QPS Free","QPS Free"] ;

        $http.get($scope.dnsdataurl)
            .success(function(data, status) {
                $scope.status = status;
                $scope.dnsstatsresults = data;
                var aggLabels = [];
                var aggValues = [];
                var aggQps = [];
                var aggValues3 = [];
                var aggValues4 = [];
                angular.forEach($scope.dnsstatsresults, function(results)
                {
                    aggLabels = aggLabels.concat(results.dnsdate);
                    aggValues = aggValues.concat(results.hits);
                    aggQps = aggQps.concat(results.qps);
                    aggValues3 = aggValues3.concat(results.qps*1000);
                    aggValues4 = aggValues4.concat('3'); // TODO Klant QPS pakket grootte per klant ophalen
                });
                $scope.labels = aggLabels ;
                $scope.values = [aggValues, aggQps, aggValues3] ;

                $scope.qpsValue = [aggQps, aggValues4] ;
                $scope.qpsLabel = $scope.labels ;

                //console.log($scope.dnsstatsresults);
                //console.log(aggValues2);
            });


    };

    $scope.getDnsStats = function($domainId) {
        $scope.dnsnamedataurl = '/getrecorddomainname/'+$domainId ;

        $http.get($scope.dnsnamedataurl).
            success(function(dnsData, status) {
                $scope.dnsDomainName = dnsData;
                $scope.status = status;
                //$scope.retrieveDnsStats(); // Load stats results
                $scope.showGraph();
            })
            .
            error(function(dnsData, status) {
                $scope.dnsDomainName = dnsData || "Request failed";
                $scope.status = status;
               // return 'error';
            });
    };

    //$scope.retrieveDnsStats = function() {
    //    $scope.dnsdataurl = '/fetchstats/'+$scope.dnsDomainName ;
    //
    //    // retrieve full record set
    //
    //    $http.get($scope.dnsdataurl)
    //        .success(function(data, status) {
    //            $scope.status = status;
    //            $scope.dnsstatsresult = data;
    //            //angular.forEach($scope.dnsstatsresult, function(labels)
    //            //{
    //            //    console.log(labels.dnsdate) ;
    //            //})
    //        });
    //};
});

myproapp.controller('dnsRecordsController', function($scope,$http,$timeout){

    $scope.processingChanges=false;
    $scope.changed=false;
    $scope.domainname='';

    $scope.init = function($domainId) {
        if ($domainId==0)
        {
            // Ok
        }
        else
        {
            $scope.refreshRecords($domainId);
        }
    };

    $scope.setChanged = function($domainId,$recordId,$requestType,$changedData) {
        $scope.changed=true;
        $scope.UpdRecord($domainId, $recordId, $requestType, $changedData) ;
        //console.log($recordId+': '+$requestType+': '+$changedData) ;
    };

    $scope.refreshRecords = function($domainId) {
        $scope.dataurl = '/fetchrecords/'+$domainId ;
        $scope.changed=false;

        $http.get($scope.dataurl).
            success(function(data, status) {
                $scope.status = status;
                $scope.result = data;
            })
            .
            error(function(data, status) {
                $scope.result = data || "Request failed";
                $scope.status = status;
            });

        $scope.getRecordsDomainName($domainId);
    };

    $scope.getRecordsDomainName = function($domainId) {
        $scope.dataurl = '/getrecorddomainname/'+$domainId ;
        $scope.changed=false;

        $http.get($scope.dataurl).
            success(function(data, status) {
                //$scope.status = status;
                $scope.domainname = data;
            })
            .
            error(function(data, status) {
                $scope.result = data || "Request failed";
                //$scope.status = status;
            });
    };

    $scope.DelRecord = function($domainId, $recordId) {

        $scope.processingChanges=true;

        $scope.delUrl = '/deleterecords/'+$recordId ;

        $http.post($scope.delUrl).
            success(function(data, status) {
                $scope.status = status;
            })
            .
            error(function(data, status) {
                $scope.status = status;
            });

        $scope.callAtTimeout = function() {
            $scope.refreshRecords($domainId);
            $scope.processingChanges=false;
        };

        $timeout( function(){ $scope.callAtTimeout(); }, 1000);

    };

    $scope.UpdRecord = function($domainId, $recordId, $requestType, $changedData) {

        $scope.processingChanges=true;

        var data = {'recId': $recordId, 'recType': $requestType, 'chData': $changedData };
        $http({
            method: 'POST',
            url: '/updrec',
            headers: { 'Content-Type' : 'application/x-www-form-urlencoded'},

            data: $.param(data)
        }).
                success(function(data, status) {
                    $scope.status = status;
                    $scope.updResult = data;
                })
                .
                error(function(data, status) {
                    $scope.status = status;
                    $scope.updResult = '{"Error":"Error while updating database!"}' ;
                });

        //$scope.postUrl = '/updaterecords/'+$recordId+'/'+$requestType+'/'+$changedData ;
        //$http.post($scope.postUrl).
        //    success(function(data, status) {
        //        $scope.status = status;
        //        $scope.updResult = data;
        //    })
        //    .
        //    error(function(data, status) {
        //        $scope.status = status;
        //        $scope.updResult = '{"Error":"Error while updating database!"}' ;
        //    });

        $timeout( function(){ $scope.callAtTimeout2(); }, 1000);

        $scope.callAtTimeout2 = function() {
            $scope.refreshRecords($domainId);
            $scope.processingChanges=false;
        };

    };

    // TODO Public function, how to?!?
    //$scope.callAtTimeout3 = function() {
    //    $scope.refreshRecords($domainId);
    //    $scope.processingChanges=false;
    //};

});


}).call(this,require("htZkx4"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_769815f6.js","/")